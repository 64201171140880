import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoadingIndicatorService} from '../_services';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        LoadingIndicatorService.showLoadingIndicator();
        try {
            return next.handle(request);
        }
        finally {
            LoadingIndicatorService.hideLoadingIndicator();
        }
    }
}
