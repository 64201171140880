import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, finalize, map } from 'rxjs/operators';
import { Config } from '../_config';
import { Observable, throwError } from 'rxjs';
import { NotificationService } from './notification.service';
import { Router } from '@angular/router';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private router: Router) {
  }

  login(username: string, password: string) {
    return this.http
      .post<any>(Config.API_ENDPOINT + `/auth/login`, {username, password})
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
        }

        return user;
      }));
  }

  refreshToken(): Observable<any> {
    const user = this.user();

    if (user && user.refresh_token) {
      const refresh_token = user.refresh_token;

      return this.http
        .post<any>(Config.API_ENDPOINT + `/token/refresh`, {refresh_token})
        .pipe(
          map(refreshedUser => {
            // refresh successful if there's a jwt token in the response
            if (refreshedUser && refreshedUser.token) {
              user.token = refreshedUser.token;
              user.refresh_token = refreshedUser.refresh_token;
            }

            return user;
          }));
    } else {
      const error = 'refresh_token missing';
      return throwError(error);
    }
  }

  logout() {
    if (!this.isLoggedIn()) {
      return;
    }

    this.http
      .post<any>(Config.API_ENDPOINT + `/auth/logout`, {})
      .pipe(
        finalize(() => {
          // remove user from local storage to log user out
          localStorage.removeItem('currentUser');
          location.reload(true);
        })
      )
      .subscribe();
  }

  user() {
    const userJSON = localStorage.getItem('currentUser');
    return userJSON ? JSON.parse(userJSON) : null;
  }

  isLoggedIn(): boolean {
    return this.user() !== null;
  }

  getAuthToken(): string {
    const user = this.user();
    return user ? user.token : null;
  }

  hasAccess(resource): Observable<boolean> {
    if (resource === null) {
      return new Observable<boolean>(function (observer) {
        observer.next(true);
        observer.complete();
      });
    }

    return this.http
      .post<any>(Config.API_ENDPOINT + `/auth/check_access`, {resource})
      .pipe(
        map(response => {
          if (response) {
            if (!response.access) {
              this.notificationService.warning('Sie besitzen nicht die benötigten Zugriffsrechte!');
              this.router.navigateByUrl('');
            }

            return response.access;
          }

          return false;
        }));
  }
}
