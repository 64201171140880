import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {AuthenticationService, NotificationService} from '../_services';
import {catchError, filter, finalize, switchMap, take} from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    isRefreshingToken = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(
        private authService: AuthenticationService,
        private notificationService: NotificationService
    ) { }

    private static addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({ setHeaders: { Authorization: `Bearer ${token}`}});
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {

        return next.handle(JwtInterceptor.addTokenToRequest(request, this.authService.getAuthToken()))
            .pipe(
                catchError(err => {
                    if (err instanceof HttpErrorResponse) {
                        const httpError = (<HttpErrorResponse>err);

                        switch (httpError.status) {
                            case 500:
                                this.notificationService.fatal(httpError.message);
                                return throwError(err);

                            case 400:
                                return <any>this.authService.logout();
                            case 401:
                                if (request.url.endsWith('/auth/logout')) {
                                  // remove user from local storage to log user out
                                  localStorage.removeItem('currentUser');
                                  location.reload(true);
                                } else if (request.url.endsWith('/token/refresh')) {
                                    return <any>this.authService.logout();
                                } else if (request.url.endsWith('/auth/login')) {
                                    return throwError(err);
                                } else {
                                    return this.handle401Error(request, next);
                                }

                            default:
                                return throwError(err);
                        }
                    } else {
                        return throwError(err);
                    }
                }));
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            return this.authService.refreshToken()
                .pipe(
                    switchMap((user) => {
                        if (user && user.token) {
                            this.tokenSubject.next(user.token);
                            localStorage.setItem('currentUser', JSON.stringify(user));
                            return next.handle(JwtInterceptor.addTokenToRequest(request, user.token));
                        }

                        return <any>this.authService.logout();
                    }),
                    catchError(() => {
                        return <any>this.authService.logout();
                    }),
                    finalize(() => {
                        this.isRefreshingToken = false;
                    })
                );
        } else {
            this.isRefreshingToken = false;

            return this.tokenSubject
                .pipe(
                    filter(token => token != null),
                    take(1),
                    switchMap(token => {
                        return next.handle(JwtInterceptor.addTokenToRequest(request, token));
                    })
                );
        }
    }
}
