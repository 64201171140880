import { Injectable } from '@angular/core';
import { ToastData, ToastOptions, ToastyService, ToastyConfig } from 'ng2-toasty';

@Injectable({ providedIn: 'root' })
export class NotificationService {
    private static toastyService: ToastyService;

    static ToastyService() {
        if (!this.toastyService) {
            const config = new ToastyConfig();
            config.position = 'top-right';
            config.timeout = 10;
            config.theme = 'bootstrap';

            this.toastyService = new ToastyService(config);
        }
        return this.toastyService;
    }

    constructor(
        private toastyService: ToastyService,
        private toastyConfig: ToastyConfig
    ) {
        this.toastyConfig.theme = 'bootstrap';
    }

    success(message: string) {
        const toastOptions: ToastOptions = {
            title: 'Erfolg',
            msg: message,
            onAdd: (toast: ToastData) => {
                /* added */
            },
            onRemove: (toast: ToastData) => {
                /* removed */
            }
        };
        this.toastyService.success(toastOptions);
    }

    error(message: string) {
        const toastOptions: ToastOptions = {
            title: 'Fehler',
            msg: message,
            onAdd: (toast: ToastData) => {
                /* added */
            },
            onRemove: (toast: ToastData) => {
                /* removed */
            }
        };
        this.toastyService.error(toastOptions);
    }

    warning(message: string) {
        const toastOptions: ToastOptions = {
            title: 'Warnung',
            msg: message,
            onAdd: (toast: ToastData) => {
                /* added */
            },
            onRemove: (toast: ToastData) => {
                /* removed */
            }
        };
        this.toastyService.warning(toastOptions);
    }

    fatal(message: string) {
        const toastOptions: ToastOptions = {
            title: 'Fehler',
            msg: message,
            onAdd: (toast: ToastData) => {
                /* added */
            },
            onRemove: (toast: ToastData) => {
                /* removed */
            },
            timeout: 0
        };
        this.toastyService.warning(toastOptions);
    }


}
