import { Injectable } from '@angular/core';

@Injectable()
export class LoadingIndicatorService {

    private static _loading = 0;

    private static doShowLoadingIndicator() {
        document.body.classList.add('loading');

    }

    private static doHideLoadingIndicator() {
        document.body.classList.remove('loading');
    }

    static get loading(): boolean {
        return this._loading > 0;
    }

    static showLoadingIndicator(): void {
        this._loading++;

        if (this._loading === 1) {
            this.doShowLoadingIndicator();
        }
    }

    static hideLoadingIndicator(): void {
        if (this._loading > 0) {
            this._loading--;

            if (this._loading === 0) {
                this.doHideLoadingIndicator();
            }
        }
    }
}
