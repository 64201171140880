import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iban'
})
export class IbanPipe implements PipeTransform {

  transform(value: string): string {
    if (!value || typeof value !== 'string') {
      return '';
    }

    value = value.replace(/\s+/g, '');
    value = value.substring(0, 32);

    const strArray = value.match(/.{1,4}/g);
    let iban = '';

    for (const str of strArray) {
      iban += str + ' ';
    }

    return iban.trim().toUpperCase();
  }
}
