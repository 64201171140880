import { Injectable } from '@angular/core';
import { SitemapService } from '../../_services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  state: string;
  main: MainMenuItems[];
}

@Injectable()
export class MenuItems {

  constructor(private sitemapService: SitemapService) {
  }

  private sitemap = this.sitemapService.getSitemap()
    .pipe(map(item => {
        const result = [];

        for (const topLevelItem of item['children']) {
          const mainMenu = {
            label: topLevelItem['name'],
            state: topLevelItem['id'],
            main: []
          };

          if (topLevelItem['children']) {
            for (const firstLevelItem of topLevelItem['children']) {
              const firstLevelMenu = {
                type: firstLevelItem['children'] ? 'sub' : 'link',
                name: firstLevelItem['name'],
                main_state: topLevelItem['id'],
                state: firstLevelItem['id'],
                icon: firstLevelItem['icon'] ? 'feather icon-' + firstLevelItem['icon'] : '',
                children: []
              };

              if (firstLevelItem['children']) {
                for (const secondLevelItem of firstLevelItem['children']) {
                  const secondLevelMenu = {
                    type: 'link',
                    name: secondLevelItem['name'],
                    state: secondLevelItem['id'],
                    icon: secondLevelItem['icon'] ? 'feather icon-' + secondLevelItem['icon'] : '',
                  };

                  firstLevelMenu['children'].push(secondLevelMenu);
                }
              }

              mainMenu['main'].push(firstLevelMenu);
            }
          }

          result.push(mainMenu);
        }

        return result;
      })
    );

  getAll(): Observable<Menu[]> {
    return this.sitemap;
  }
}
