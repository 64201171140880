import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { MainComponent } from './main/main.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { MenuItems } from './shared/menu-items/menu-items';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor, LoadingInterceptor } from './_helpers';
import { ToastyModule } from 'ng2-toasty';
import { BreadcrumbsComponent } from './main/breadcrumbs/breadcrumbs.component';
import { MatPaginatorIntl } from '@angular/material';
import { getGerPaginatorIntl } from './_i18n/ger-paginator-intl';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

registerLocaleData(localeDe, localeDeExtra);


@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    MainComponent,
    BreadcrumbsComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastyModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
  ],
  entryComponents: [
  ],
  schemas: [],
  providers: [
      { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
      { provide: MatPaginatorIntl, useValue: getGerPaginatorIntl()},
      { provide: LOCALE_ID, useValue: 'de' },

      MenuItems
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
