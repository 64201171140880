import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { AuthGuard } from './_guards';
import { MainComponent } from './main/main.component';

const routes: Routes = [
    {
        path: '',
        canActivate: [AuthGuard],
        component: MainComponent,
        children: [
            {
                path: '',
                loadChildren: './main/main.module#MainModule'
            }
        ]
    },
    {
        path: '',
        component: AuthComponent,
        children: [
            {
                path: 'auth',
                loadChildren: './auth/auth.module#AuthModule'
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
