import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../_config';

@Injectable({providedIn: 'root'})
export class SitemapService {
  constructor(private http: HttpClient) {
  }

  getSitemap() {
    return this.http.get<any>(Config.API_ENDPOINT + `/sitemap`);
  }
}
